import { CompensationState } from 'src/models/compensation'

const initialState: CompensationState = {
  step: 0,
  whitelabel: {
    embedded:false,
    welcoming_text: '',
    font: '',
    background_image: '',
    primary_color: '',
    secondary_color: '#40a9ff',
    logo: '',
    title: '',
    html_head: '',
    calculator_type: '',
    currency_token: '',
    back_button_txt: '',
    back_button_url: '',
    customer_type: 'individual',
    min_unit: '0.001',
    welcome_text_color: '#FFFFFF',
    thank_you_txt:'',
    thank_you_bg:'',
    payment_gateway:'REDSYS.santander',
    mandatory_calculator: false,
    show_faq:false,
    offset_reason_method:'noReason',
  },
  selectedProject: null,
  vintageId:null,
  amount: '',
  personalInfo: {
    email: '',
    first_name: '',
    last_name: '',
    vat_number: '',
    street: '',
    postal_code: '',
    city: '',
    state: '',
    country:'',
    customer_type: 'individual',
  },
  currency_token: typeof localStorage !== 'undefined' && localStorage.getItem('currency') || 'USD',
  offset_reason: 1,
  offset_reason_text:'',
  initials: '',
  projects: [],
  countries: [],
  currencies: [],
  offset_reasons:[],
  terms: false,
  commercialComms: false,
  final_price:{
    discount_amount:0,
    sell_base_amount: 0,
    fee_amount: 0,
    fee_amount_percentage: 0,
    sell_vat: 0,
    sell_vat_percentage: 0,
    fee_vat: 0,
    fee_vat_percentage: 0,
    total_amount: 0,
    fixed_amount_transaction_fee:0,
    partner_fee_percentage: 0,
    partner_fee_amount: 0,
    partner_fee_vat_amount: 0,
    supplier_discounts: {
      applied_discounts: [],
      applied_discounts_by_amount_type: [],
      supplier_discount: 0
    }
  },
  equivalence:{
    copyright: 'ClimateTrade 2023',
    execution: 0,
    library: '',
    extra: {
      kms:0,
      retired_cars:0
    },
    result: {
      measure:'',
      value:0
    },
    noLoginResult:[]
  },
  certificate:''
}
export default initialState
