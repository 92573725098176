import React from 'react'
import styles from './Header.module.scss'
import Link from 'next/link'

const Header = () => {
  return (
    <div className={styles['header-container']}>
      <div>
        <div className="customer-logo"></div>
      </div>
      <Link href="/"><img className="climate-logo" src="https://climatetrade.com/wp-content/uploads/2022/03/logo-blue-climate-trade-2.jpg"
        alt="ClimateTrade logo" /></Link>

    </div>
  )
}
export default Header
