import React, { useCallback, useEffect } from 'react'

import Header from './Header/Header'
import {
  CurrencySelect,
  Footer,
  LanguageSelect,
} from '@climatetrade/components'
import styles from './Layout.module.scss'

import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { getProjects, selectAmount, selectCurrencyToken, setCurrency, setEmail } from 'src/features/compensation/compensationSlice'

const DefaultLayout: React.FC<any> = ({ children }) => {
  const { i18n } = useTranslation()
  const router = useRouter()

  const dispatch = useAppDispatch()
  const amount = useAppSelector(selectAmount)
  const currency = useAppSelector(selectCurrencyToken)
  const getInitials = useCallback(() => router.query.initials as string || location.host.split('.').reverse()[2] ||
    router.query.initials === 'calculators' && 'events' || 'localhost', [router.query.initials])

  const getEmail = useCallback(() => router.query.mail as string, [router.query.mail])

  const handleCurrencyChange = (value: string) => {
    dispatch(setCurrency(value))
    dispatch(getProjects(getInitials(), amount || '1', router.locale))
  }
  const handleLanguageChange = (value: string) => {
    i18n.changeLanguage(value)
    localStorage.setItem('language', value)
    router.push(router.asPath, undefined, { locale: value })
  }

  useEffect(() => {
    const email = getEmail()
    dispatch(setEmail(email))
  }, [])

  return (
    <>
      <Header />
      <main className={styles.content}>{children}</main>
      <Footer language={i18n.language}>
        <LanguageSelect
          language={i18n.language}
          onChange={handleLanguageChange}
        />
        <CurrencySelect currency={currency} onChange={handleCurrencyChange} />
      </Footer>
    </>
  )
}
export default DefaultLayout
