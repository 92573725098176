import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin, SpinProps } from 'antd'
import styles from './Spinner.module.scss'
interface SpinnerProps extends SpinProps {
  fontSize?: number
}

const Spinner: React.FC<SpinnerProps> = ({ fontSize = 200, ...props }) => {
  return (
    <div className={styles['spinner-container']}>
      <Spin indicator={<LoadingOutlined style={{ fontSize }} spin />} {...props} />
    </div>
  )
}

export default Spinner
