import 'antd/dist/antd.css'
import 'src/styles/globals.scss'
import 'src/config/axios'
import { Provider } from 'react-redux'
import type { AppProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import store from 'src/app/store'
import Layout from 'src/components/layout/Layout'
import { ConfigProvider } from 'antd'
import { useRouter } from 'next/router'

import es from 'antd/lib/locale/es_ES'
import en from 'antd/lib/locale/en_US'
import fr from 'antd/lib/locale/fr_FR'
import de from 'antd/lib/locale/de_DE'
import pt from 'antd/lib/locale/pt_PT'
import ko from 'antd/lib/locale/ko_KR'
import it from 'antd/lib/locale/it_IT'
import pl from 'antd/lib/locale/pl_PL'
import { useEffect } from 'react'

const antdLanguages = { es, en, fr, de, pt, ko, it, pl }
const MyApp = ({ Component, pageProps }: AppProps) => {

  const router = useRouter()
  useEffect(() => {
    const locale = localStorage.getItem('language')
    if (locale) {
      router.push(router.asPath, undefined, { locale })
    } else {
      localStorage.setItem('language', router.locale)
    }
  }, [])
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      const timer = setInterval(() => {
        router.replace(router.asPath, undefined, {
          scroll: false,
        })
      }, 8000)
      return () => clearTimeout(timer)
    }
  }, [router])
  return (
    <Provider store={store}>
      <ConfigProvider locale={antdLanguages[router.locale]}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ConfigProvider>
    </Provider>
  )
}
export default appWithTranslation(MyApp)
