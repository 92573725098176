import axios from 'axios'
import { } from 'next/router'

const currencies ={
  '$':'USD',
  '€':'EUR',
  '£':'GBP',
  'USD':'USD',
  'EUR':'EUR',
  'GBP':'GBP',
  'CLP':'CLP',
}

axios.defaults.baseURL = process.env.API_URL
axios.interceptors.request.use(function (config) {
  config.headers.common['accept-language'] = localStorage.getItem('language') || 'en'
  const currency = localStorage.getItem('currency')
  if(config.url.includes('calculator')){
    return config
  }
  if(currency?.length>25){
    const currencyCode = JSON.parse(currency)?.code || 'USD'
    localStorage.setItem('currency',currencyCode)
    config.headers.common['x-currency-code'] = currencyCode
  }
  else if (currency) {
    config.headers.common['x-currency-code'] = currencies[currency] || 'USD'
  }
  return config
})

export default axios
