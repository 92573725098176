import { PayloadAction } from '@reduxjs/toolkit'
import {
  CompensationState,
  Country,
  Currency,
  Equivalence,
  FinalUnitPrice,
  OffsetReason,
  PersonalInfo,
  Whitelabel,
} from 'src/models/compensation'
import { Project } from 'src/models/project'
const compensationReducers = {
  setAmount: (state: CompensationState, action: PayloadAction<string>) => {
    const decimalPlaces = state.whitelabel.min_unit === '1' ? 0 : 3
    const amount = action.payload
    state.amount =
      amount.split('.')[1]?.length > decimalPlaces
        ? (+amount).toFixed(decimalPlaces)
        : amount
  },
  setCurrency: (state: CompensationState, action: PayloadAction<string>) => {
    localStorage.setItem('currency', action.payload)
    state.currency_token = action.payload
  },
  setStep: (state: CompensationState, action: PayloadAction<number>) => {
    state.step = action.payload
  },
  setProjects: (state: CompensationState, action: PayloadAction<Project[]>) => {
    state.projects = action.payload
  },
  setWhitelabel: (
    state: CompensationState,
    action: PayloadAction<Whitelabel>
  ) => {
    state.whitelabel = action.payload
  },
  setSelectedProject: (
    state: CompensationState,
    action: PayloadAction<Project>
  ) => {
    state.selectedProject = action.payload
    state.vintageId = action.payload?.is_vintage
      ? action.payload.vintage_items[0].id
      : null
  },
  setVintageId: (state: CompensationState, action: PayloadAction<number>) => {
    state.vintageId = action.payload
  },
  setPersonalInfo: (
    state: CompensationState,
    action: PayloadAction<PersonalInfo>
  ) => {
    state.personalInfo = action.payload
  },
  setCustomerType: (
    state: CompensationState,
    action: PayloadAction<'individual' | 'company'>
  ) => {
    state.personalInfo.customer_type = action.payload
  },
  setCountries: (
    state: CompensationState,
    action: PayloadAction<Country[]>
  ) => {
    state.countries = action.payload
  },
  setCurrencies: (
    state: CompensationState,
    action: PayloadAction<Currency[]>
  ) => {
    state.currencies = action.payload
  },
  setTerms: (state: CompensationState, action: PayloadAction<boolean>) => {
    state.terms = action.payload
  },
  setCommercialComms: (
    state: CompensationState,
    action: PayloadAction<boolean>
  ) => {
    state.commercialComms = action.payload
  },
  setFinalPrice: (
    state: CompensationState,
    action: PayloadAction<FinalUnitPrice>
  ) => {
    state.final_price = action.payload
  },
  setEquivalence: (
    state: CompensationState,
    action: PayloadAction<Equivalence>
  ) => {
    state.equivalence = action.payload
  },
  setOffsetReasons: (
    state: CompensationState,
    action: PayloadAction<OffsetReason[]>
  ) => {
    state.offset_reasons = action.payload
  },
  setOffsetReason: (
    state: CompensationState,
    action: PayloadAction<number>
  ) => {
    state.offset_reason = action.payload
    state.offset_reason_text = ''
  },
  setOffsetReasonText: (
    state: CompensationState,
    action: PayloadAction<string>
  ) => {
    state.offset_reason_text = action.payload
  },
  setCertificate: (state: CompensationState, action: PayloadAction<string>) => {
    state.certificate = action.payload
  },
  setEmail: (state: CompensationState, action: PayloadAction<string>) => {
    state.personalInfo.email = action.payload
  },
}
export default compensationReducers
