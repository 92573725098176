import {
  fetchCountries,
  fetchCurrencies,
  fetchEquivalence,
  fetchFinalPrice,
  finishOffsetPayment,
} from 'src/features/compensation/compensationAPI'

import { createSlice } from '@reduxjs/toolkit'

import type { AppState } from 'src/app/store'
import { fetchProjects } from './compensationAPI'
import { Currency } from 'src/models/compensation'
import compensationReducers from './reducers'
import initialState from './state'

export const compensationSlice = createSlice({
  name: 'compensation',
  initialState,
  reducers: compensationReducers,
})
export const {
  setAmount,
  setCurrency,
  setStep,
  setProjects,
  setSelectedProject,
  setWhitelabel,
  setPersonalInfo,
  setCurrencies,
  setCountries,
  setCustomerType,
  setTerms,
  setCommercialComms,
  setFinalPrice,
  setEquivalence,
  setEmail,
  setVintageId,
  setOffsetReasons,
  setOffsetReason,
  setOffsetReasonText,
  setCertificate,
} = compensationSlice.actions

export const selectAmount = (state: AppState) => state.compensation.amount
export const selectCommercialComms = (state: AppState) =>
  state.compensation.commercialComms
export const selectCurrencyToken = (state: AppState) =>
  state.compensation.currency_token
export const selectCurrencies = (state: AppState) =>
  state.compensation.currencies
export const selectCurrency = ({
  compensation: { currency_token, currencies },
}): Currency => {
  return currencies.find(
    (c) => c.code === currency_token || c.symbol === currency_token
  )
}
export const selectStep = (state: AppState) => state.compensation.step
export const selectProjects = (state: AppState) => state.compensation.projects
export const selectSelectedProject = (state: AppState) =>
  state.compensation.selectedProject
export const selectWhitelabel = (state: AppState) =>
  state.compensation.whitelabel
export const selectPersonalInfo = (state: AppState) =>
  state.compensation.personalInfo
export const selectCountries = (state: AppState) =>
  state.compensation.countries
export const selectTerms = (state: AppState) => state.compensation.terms
export const selectFinalPrice = (state: AppState) =>
  state.compensation.final_price
export const selectFinalUnitPrice = ({
  compensation: { selectedProject, vintageId },
}) =>
  selectedProject?.is_vintage && vintageId
    ? selectedProject.vintage_items.find((i) => i.id === vintageId)
        ?.final_unit_price
    : selectedProject?.final_unit_price

export const selectEquivalence = (state: AppState) =>
  state.compensation.equivalence

export const selectOffsetReasons = (state: AppState) =>
  state.compensation.offset_reasons
export const selectOffsetReason = (state: AppState) =>
  state.compensation.offset_reason
export const selectOffsetReasonText = (state: AppState) =>
  state.compensation.offset_reason_text
export const selectVintageId = (state: AppState) =>
  state.compensation.vintageId
export const selectCertificate = (state: AppState) =>
  state.compensation.certificate
export const getProjects =
  (initials: string, amount = '1', locale = 'en') =>
  async (dispatch) => {
    const { projects_with_prices, whitelabel, offset_reasons } =
      await fetchProjects(initials, amount, locale)
    dispatch(setProjects(projects_with_prices))
    if (projects_with_prices?.length > 0) {
      dispatch(setSelectedProject(projects_with_prices[0]))
      dispatch(getFinalPrice(initials, +amount))
    } else {
      dispatch(setSelectedProject(null))
    }
    if (whitelabel) {
      if (whitelabel.customer_type !== 'choose') {
        dispatch(setCustomerType(whitelabel.customer_type))
      }
      dispatch(setWhitelabel(whitelabel))
      if (!localStorage.getItem('currency')) {
        dispatch(setCurrency(whitelabel.currency_token))
      }
      if (whitelabel.html_head) {
        document.head.innerHTML += whitelabel.html_head
      }
    }
    dispatch(setOffsetReasons(offset_reasons))
    return projects_with_prices
  }
export const getFinalPrice =
  (initials: string, amount = 1) =>
  async (dispatch, getState) => {
    const {
      compensation: {
        vintageId,
        selectedProject: project,
        personalInfo: { country },
      },
    } = getState()
    const project_id = project.id
    const vintage_item_id = project.is_vintage
      ? vintageId || project.vintage_items[0]?.id
      : undefined
    const params = {
      amount,
      project_id,
      vintage_item_id,
      initials,
      country,
      source: 'whitelabel',
    }
    const finalPrice = await fetchFinalPrice(params)
    dispatch(setFinalPrice(finalPrice))
    return finalPrice
  }
export const getCurrencies = () => async (dispatch) => {
  const currencies = await fetchCurrencies()
  dispatch(setCurrencies(currencies))
  return currencies
}
export const getCountries = () => async (dispatch) => {
  const countries = await fetchCountries()
  dispatch(setCountries(countries))
  return countries
}
export const getEquivalence = (tCO2: number) => async (dispatch) => {
  const equivalence = await fetchEquivalence(tCO2)
  dispatch(setEquivalence(equivalence))
  return equivalence
}
export const finishOffset = (payload) => async () => {
  return finishOffsetPayment(payload)
}

export default compensationSlice.reducer
