import React from 'react'
import { useAppSelector } from 'src/app/hooks'
import { selectWhitelabel } from 'src/features/compensation/compensationSlice'

const DynamicStyles: React.FC = () => {
  const {
    secondary_color, primary_color, background_image, logo, font, welcome_text_color
  } = useAppSelector(selectWhitelabel)
  const secondary = secondary_color || '#40a9ff'
  return (
    <style>{
      `
      .ant-input:hover,.box:hover, .ant-select:hover, .input-container:hover > div  {
        border-color: ${secondary} !important;
      }
      .ant-input:focus, .ant-input-focused, .ant-select:focus, .ant-select-focused, .input-container:focus > div {
        border-color: ${secondary}!important;
        box-shadow: 0 0 0 2px ${(secondary ? secondary + '36' : 'rgb(24 144 255 / 20%)')}!important;
      }
      .ant-select-single.ant-select-open .ant-select-selection-item{
        color:${secondary} !important;
      }
      .description{
        color:${secondary}
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${primary_color || '#D9D9D9'};
        border-radius: 0.3em;
      }
      .offset-title {
        font-size: rem(40px);
        color: ${welcome_text_color}!important;
      }
      @font-face {
        font-family: Font-primary;
        src: url('${font}') format('truetype');
    }
      body{
        font-family:${font ? 'Font-primary' : 'Inter'};
        background-image:${background_image ? 'url(' + background_image + ')' : 'unset'};
      }

      .customer-logo{
        background-image:${logo ? 'url(' + logo + ')' : 'unset'};
      }
      `
    }</style>
  )
}
export default DynamicStyles
