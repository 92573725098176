import { useAppSelector } from 'src/app/hooks'
import { selectWhitelabel, } from 'src/features/compensation/compensationSlice'
import dynamic from 'next/dynamic'
import DynamicStyles from 'src/styles/DynamicStyles'
import EmbeddedLayout from './EmbeddedLayout/EmbeddedLayout'
import DefaultLayout from './DefaultLayout'
import Spinner from '../Spinner/Spinner'

const Layout = ({ children }) => {

  const { embedded, currency_token } = useAppSelector(selectWhitelabel)

  const CurrentLayout = embedded && currency_token ? EmbeddedLayout : DefaultLayout
  return (
    <>
      <DynamicStyles />
      {currency_token ? <CurrentLayout >{children}</CurrentLayout>
      :<> <Spinner/>
        {children} </>
      }
    </>
  )
}

export default dynamic(() => Promise.resolve(Layout), { ssr: false })
