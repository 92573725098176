import axios from 'axios'
import { Country, Currency, Equivalence, FinalUnitPrice, OffsetReason, Whitelabel } from 'src/models/compensation'
import { Project } from 'src/models/project'
export async function fetchCurrencies(): Promise<Currency[]> {
  const response = await axios.get('/api/v1/currency_tokens/')
  return response.data.results
}
export async function fetchCountries(): Promise<Country[]> {
  const response = await axios.get('/api/v1/countries/', { params: { page_size: 9999 } })
  return response.data.results
}
export async function fetchFinalPrice(params)
: Promise<FinalUnitPrice> {
  const response = await axios.get('/api/v1/final_price/', { params })
  return response.data
}
interface ProjectResponse { projects_with_prices: Project[], whitelabel: Whitelabel, offset_reasons:OffsetReason[] }
export async function fetchProjects(initials: string, amount = '1', locale = 'en'): Promise<ProjectResponse> {
  const projects_response = await axios.get('/api/v1/widget/get_projects_widget/', { params: { initials } })
  const { data: { data: projects_prices_response, whitelabel,offset_reasons } } = await axios.get('/api/v1/widget/get_projects_widget_prices/', {
    params: { initials, amount:1 }
  })
  const { countries } = await import(`public/countries/${locale}.json`)
  const projects_with_prices: Project[] = projects_response.data.results
    .map(p => ({
      ...p,
      country: {
        ...p.country,
        name: countries[p.country?.code2]
      }
    })).filter(p => p.is_active)
    .filter(p => (p.is_vintage?p.get_vintage_items_available_sum_stock: p.co2_balance) > +amount)
  projects_prices_response.sort((a, b) => a.rating - b.rating)
  return { projects_with_prices, whitelabel,offset_reasons }
}
export async function createCreditCardOrder(payload): Promise<any> {
  const response = await axios.post('/api/v1/credit_card_info/', payload)
  return response.data
}
export  function finishCreditCard(orderId, initials, locale): Promise<any> {
  const offset = JSON.parse(localStorage.getItem('compensation_offset'))
  const ticket_data = JSON.parse(localStorage.getItem('ticket_data'))
  ticket_data.country = ticket_data.country || ''
  const consent = JSON.parse(localStorage.getItem('consent'))
  const req_body = {
    orderId, offset, ticket_data, initials, integration_code: initials, consent: consent
  }

  return axios.post('/api/v1/carts/credit_card_payment_standard/', req_body, {
    headers: {
      'accept-language': localStorage.getItem('language') || locale
    }
  })
}

export async function fetchEquivalence(tCO2:number): Promise<Equivalence> {
  const response = await axios.post('https://api.climatetrade.com/calculate/equivalence', {tCO2})
  return response.data
}

export async function finishOffsetPayment(payload): Promise<any> {
  const response = await axios.post('/api/v1/payments/offset_whitelabel/', payload)
  return response.data
}
